import React from 'react'
import styled, { css } from 'styled-components'
import { Container, LocaleConfig, TextBlock, useTranslations } from '../core'
import { withPrefix } from 'gatsby'

interface PaymentOptionsProps {
    locale: LocaleConfig
    title: string
    content: string
}

export const PaymentOptions = ({
    locale,
    title,
    content,
}: PaymentOptionsProps) => {
    const { translate } = useTranslations(locale.locale)

    return (
        <Container>
            <Wrapper>
                <InfoContainer>
                    <Title>{title}</Title>
                    <TextBlock dangerouslySetInnerHTML={{ __html: content }} />
                </InfoContainer>
                <CardsContainer>
                    <Card>
                        <CardHeader>
                            <CardIcon icon="company.svg" />
                            {translate('corporate_payment')}
                        </CardHeader>
                        <CardContent>
                            <CardContentHeading>
                                {translate('payments')}
                            </CardContentHeading>
                            {translate('one_time_payment')}
                            <br />
                            <br />
                            <CardContentHeading>
                                {translate('payment_method')}
                            </CardContentHeading>
                            <Payments>
                                <PaymentIcon icon="banktransfer.png" />
                            </Payments>
                            <CardNote>
                                {translate('corporate_payment_note')}
                            </CardNote>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader>
                            <CardIcon icon="individual.svg" />
                            {translate('individual_payment')}
                        </CardHeader>
                        <CardContent>
                            <CardContentHeading>
                                {translate('payments')}
                            </CardContentHeading>
                            {translate('one_time_payment')}
                            <br />
                            <br />
                            <CardContentHeading>
                                {translate('payment_method')}
                            </CardContentHeading>
                            <Payments>
                                <PaymentIcon icon="visa.png" />
                                <PaymentIcon icon="mastercard.png" />
                                <PaymentIcon icon="amex.png" />
                                <PaymentIcon icon="jcb.png" />
                                <PaymentIcon icon="kombini.png" />
                                <PaymentIcon icon="banktransfer.png" />
                                <PaymentIcon
                                    style={{ width: 70 }}
                                    icon="payeasy.png"
                                />
                            </Payments>
                        </CardContent>
                    </Card>
                </CardsContainer>
            </Wrapper>
        </Container>
    )
}

const Wrapper = styled.div`
    position: relative;
    padding: 40px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 0;
        margin: 0 -12px;
    }
`

const InfoContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.lightBackground};
    padding: 40px 54% 40px 40px;
    font-size: 14px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 12px;
        margin-bottom: 180px;
        padding-bottom: 150px;
    }
`

const Title = styled.h3`
    font-size: 20px;
    ${({ theme }) => theme.typography_presets.bold};
    margin: 0 0 12px;
    padding: 0;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        margin: 32px 0 16px 0;
    }
`

const CardsContainer = styled.div`
    position: absolute;
    top: 0;
    right: 80px;
    height: 100%;
    width: calc(50% - 80px);
    display: flex;
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: calc(100% - 24px);
        height: auto;
        left: 50%;
        transform: translate(-50%, 70%);
    }
`

const Card = styled.div`
    width: 47%;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 48%;
    }
`

const CardHeader = styled.div`
    height: 98px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
    ${({ theme }) => theme.typography_presets.bold}

    ${Card}:nth-child(1) & {
        background-color: #469eb0;
    }

    ${Card}:nth-child(2) & {
        background-color: #02877c;
    }

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 14px;
        line-height: 20px;
    }
`

const CardIcon = styled.div<{ icon: string }>`
    width: 25px;
    height: 25px;
    background-image: url('${({ icon }) =>
        withPrefix(`/images/icons/${icon}`)}');
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 9px;
`

const CardContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    font-size: 14px;

    span {
        color: #666666;
    }

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 8px;
    }
`

const CardContentHeading = styled.div`
    font-size: 14px;
    line-height: 20px;
    ${({ theme }) => theme.typography_presets.bold};
    margin-bottom: 4px;
`

const CardNote = styled.div`
    width: 100%;
    margin-top: 16px;
    font-size: 10px;
    line-height: 13px;
`

const Payments = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -2px;
`

const PaymentIconStyles = css`
    width: 38px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
`

const PaymentIcon = styled.div<{ icon: string }>`
    ${PaymentIconStyles}
    background-image: url('${({ icon }) =>
        withPrefix(`/images/icons/${icon}`)}');
    margin-right: 2px;
    margin-bottom: 4px;

    &:nth-child(4n) {
        margin-right: 0;
    }
`
