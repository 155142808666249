import React from 'react'
import { graphql } from 'gatsby'
import {
    Layout,
    SEO,
    PageTitle,
    SectionSpacer,
    usePageSections,
    useTranslations,
    LocaleConfig,
} from 'modules/core'
import { Clients } from 'modules/clients'
import {
    IntroductionFlow,
    PaymentOptions,
    HRDashboard,
    SalesPages,
} from 'modules/hr'

type HRSection =
    | 'checkEmployees'
    | 'clients'
    | 'introductionFlow'
    | 'salesPages'
    | 'paymentOptions'

export const query = graphql`
    query HRQuery($locale: String) {
        allMarkdownRemark(
            filter: {
                frontmatter: { page: { eq: "hr" }, locale: { eq: $locale } }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        section
                        title
                    }
                    html
                }
            }
        }
    }
`

interface HRPageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
    }
    data: {
        allMarkdownRemark: {
            edges: Array<{
                node: {
                    frontmatter: {
                        section: HRSection
                        title: string
                    }
                    html: string
                }
            }>
        }
    }
}

const HRPage = ({ data, pageContext, path }: HRPageProps) => {
    const { translate } = useTranslations(pageContext.localeConfig.locale)

    const sections = usePageSections<HRSection>(data)

    return (
        <Layout locale={pageContext.localeConfig} path={path}>
            <SEO title={translate('page_hr')} />
            <PageTitle>{translate('page_hr')}</PageTitle>
            <IntroductionFlow
                locale={pageContext.localeConfig}
                title={sections.introductionFlow.title}
            />
            <SectionSpacer />
            <SalesPages
                title={sections.salesPages.title}
                content={sections.salesPages.content}
            />
            <SectionSpacer />
            <PaymentOptions
                locale={pageContext.localeConfig}
                title={sections.paymentOptions.title}
                content={sections.paymentOptions.content}
            />
            <SectionSpacer />
            <HRDashboard
                title={sections.checkEmployees.title}
                content={sections.checkEmployees.content}
            />
            <SectionSpacer />
            <SectionSpacer />
            <Clients
                locale={pageContext.localeConfig}
                title={sections.clients.title}
                content={sections.clients.content}
            />
        </Layout>
    )
}

export default HRPage
