import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, TextBlock } from 'modules/core'
import { ImageFileData, getImageFallbackSrc } from 'modules/core/graphql'

export interface SalesPagesData {
    file: ImageFileData
}

interface SalesPagesProps {
    title: string
    content: string
}

export const SalesPages = ({ title, content }: SalesPagesProps) => {
    const data = useStaticQuery<SalesPagesData>(graphql`
        query SalesPagesQuery {
            file(name: { eq: "salespages" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 500, quality: 100)
                }
            }
        }
    `)

    return (
        <Container>
            <Wrapper>
                <InfoContainer>
                    <Illustration
                        image={getImageFallbackSrc(data.file)!}
                        data-testid="hr-salespages-illustration"
                    />
                    <Content>
                        <Title>{title}</Title>
                        <TextBlock
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    </Content>
                </InfoContainer>
            </Wrapper>
        </Container>
    )
}

const Wrapper = styled.div`
    position: relative;
    padding: 40px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 0;
        margin: 0 -12px;
    }
`

const Content = styled.div`
    width: 44%;
    margin: 0 0 0 auto;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
    }
`

const InfoContainer = styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.colors.lightBackground};
    padding: 40px;
    font-size: 14px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 12px;
        margin-bottom: 180px;
        padding-bottom: 150px;
    }
`

const Title = styled.h3`
    font-size: 20px;
    ${({ theme }) => theme.typography_presets.bold};
    margin: 0 0 12px;
    padding: 0;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        margin: 32px 0 16px 0;
    }
`

const Illustration = styled.div<{ image: string }>`
    position: absolute;
    top: -10px;
    left: 0;
    height: 100%;
    width: calc(60% - 80px);
    display: flex;
    justify-content: space-between;
    background-image: url('${({ image }) => image}');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: calc(100% - 24px);
        left: 50%;
        transform: translate(-50%, 50%);
    }
`
