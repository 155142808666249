import React, { Fragment } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Container, PageSubTitle, ContentSpacer, LocaleConfig } from '../core'

const extractLinkRegexp = /(.*)\[(.*)]\((.*)\)(.*)/g

/**
 * Helper to extract markdown-like links out of content strings and
 * render them using gatsby's `Link` component.
 */
const renderContent = (content: string) => {
    const parsedForLink = extractLinkRegexp.exec(content)

    if (parsedForLink !== null) {
        return (
            <span data-testid="hr-introductionflow-content-with-link">
                {parsedForLink[1]}
                <StyledLink to={parsedForLink[3]}>
                    {parsedForLink[2]}
                </StyledLink>
                {parsedForLink[4]}
            </span>
        )
    }

    return content
}

interface IntroductionFlowProps {
    locale: LocaleConfig
    title: string
}

export interface IntroductionFlowData {
    allIntroductionFlowYaml: {
        edges: Array<{
            node: {
                content: { ja: string; en: string }
            }
        }>
    }
}

export const IntroductionFlow = ({ locale, title }: IntroductionFlowProps) => {
    const data: IntroductionFlowData = useStaticQuery(graphql`
        query IntroductionFlowQuery {
            allIntroductionFlowYaml {
                edges {
                    node {
                        content {
                            ja
                            en
                        }
                    }
                }
            }
        }
    `)

    const steps = data.allIntroductionFlowYaml.edges.map(
        ({ node }) => node.content[locale.locale]
    )

    return (
        <Container>
            <PageSubTitle>{title}</PageSubTitle>
            <ContentSpacer size="large" />
            <Steps>
                {steps.map((step, index) => (
                    <Fragment key={index}>
                        <StepHeader stepIndex={index}>
                            Step&nbsp;{index + 1}.
                        </StepHeader>
                        <StepDescription>{renderContent(step)}</StepDescription>
                    </Fragment>
                ))}
            </Steps>
        </Container>
    )
}

const STEP_COLORS = ['#FBE157', '#F6D143', '#EEC32D', '#EFB946', '#EDAD2E']

const Steps = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        flex-direction: column;
    }
`

const StepHeader = styled.span<{ stepIndex: number }>`
    position: relative;
    width: 20%;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.typography.boldFontFamily};
    background-color: ${({ stepIndex }) => STEP_COLORS[stepIndex]};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    font-size: 14px;
    letter-spacing: 2px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 83px;
        align-items: flex-start;
        padding: 4px;
        margin-bottom: 0;
        transform: translate(10px, 50%);
        letter-spacing: 0;
    }
`

const StepDescription = styled.span`
    width: 80%;
    padding: 24px 41px 24px 24px;
    background-color: ${({ theme }) => theme.colors.lightBackground};
    margin-bottom: 24px;
    font-size: 14px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        padding: 22px 10px 16px 10px;
        margin-bottom: 0;
    }
`

const StyledLink = styled(Link)`
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 12px;
    color: ${STEP_COLORS[4]};

    @media (hover: hover) {
        &:hover {
            text-decoration: underline;
        }
    }
`
