import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Container, PageSubTitle, ContentSpacer, TextBlock } from 'modules/core'
import { getImageFallbackSrc, ImageFileData } from 'modules/core/graphql'

interface HRDashboardProps {
    title: string
    content: string
}

export interface HRDashboardData {
    file: ImageFileData
}

export const HRDashboard = ({ title, content }: HRDashboardProps) => {
    const data: HRDashboardData = useStaticQuery(graphql`
        query HRDashboardQuery {
            file(name: { eq: "hrdashboard" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 1400, quality: 100)
                }
            }
        }
    `)

    return (
        <Container>
            <PageSubTitle>{title}</PageSubTitle>
            <ContentSpacer />
            <TextBlock
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
            <ContentSpacer size="large" />
            <ImageContainer
                style={{
                    backgroundImage: `url(${getImageFallbackSrc(data.file)})`,
                }}
                data-testid="hr-hrdashboard-illustration"
            />
        </Container>
    )
}

const ImageContainer = styled.div`
    height: 420px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        height: 240px;
    }
`
